@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");

html {
  --appBackground: #fff;
  --bright1: #7d7c7a;
  --bright2: #d2d2cf;
  --secondBackground: #555;
  --primaryColor: #6ec5ab;
  --successColor: #0ff;
  --successText: #3d3d3d;
  --failColor: #ff656466;
  --cancelButton: #989898;
  --headerFontSize: 24px;
  --normalFontSize: 16px;
  --tagFontSize: 12px;
  --svgHoverColor: #333;
  --primaryTextColor: #555;
  --secondaryTextColor: #3d3d3d;
  --disabledText: #999999;
  max-height: 100vh;
  max-width: 100vw;
  overflow-y: auto;
}

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  background: var(--appBackground);
  padding: 24px 16px;
  position: relative;
  width: 100vw;
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  text-align: center;
}

* {
  color: var(--primaryTextColor);
  font-size: var(--normalFontSize);
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.mainWebcam {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  width: 100vw;
  transition: all 0.6s;
}
#parallax {
  width: 250%;
  opacity: 0.25;
  animation: move-parallax 60s ease-in-out infinite;
}
@keyframes move-parallax {
  from {
    left: -10%;
    top: 40%;
    height: 30%;
  }
  50% {
    left: -100%;
    top: -120%;
    height: 300%;
  }
  to {
    left: -10%;
    top: 40%;
    height: 30%;
  }
}
