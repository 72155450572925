.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-row-start {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-reverse {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-start;
  align-items: center;
}
.flex-row-around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.flex-column-ss {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-column-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}
.flex-column-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flex-column-around {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.flex-column-between {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.flex-row-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-row-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-row-column-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-column {
  flex-direction: column;
}

.fit-content {
  width: fit-content;
}
.full-width {
  width: 100%;
}
.full-height {
  height: 100%;
}
.full-size {
  height: 100%;
  width: 100%;
}
.pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}
.pointer-events-none {
  pointer-events: none;
}
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}
.w-95 {
  width: 95%;
}
.red {
  background: red;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.primaryColor {
  background: var(--primaryColor);
}

.round {
  border-radius: 50%;
}
.disabledText {
  color: var(--disabledText);
  pointer-events: none;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.transition {
  transition: all 0.3s;
}
.m-0 {
  margin: 0;
}
.m-1 {
  margin: 8px;
}
.m-2 {
  margin: 16px;
}
.m-3 {
  margin: 24px;
}
.m-4 {
  margin: 32px;
}
.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}
.mx-1 {
  margin-left: 8px;
  margin-right: 8px;
}
.mx-2 {
  margin-left: 16px;
  margin-right: 16px;
}
.mx-3 {
  margin-left: 24px;
  margin-right: 24px;
}
.mx-4 {
  margin-left: 32px;
  margin-right: 32px;
}
.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}
.my-1 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.my-2 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-3 {
  margin-top: 24px;
  margin-bottom: 24px;
}
.my-4 {
  margin-top: 32px;
  margin-bottom: 32px;
}
.ml-0 {
  margin-left: 0px;
}
.ml-1 {
  margin-left: 8px;
}
.ml-2 {
  margin-left: 16px;
}
.ml-3 {
  margin-left: 24px;
}
.ml-4 {
  margin-left: 32px;
}
.mr-0 {
  margin-right: 0px;
}
.mr-1 {
  margin-right: 8px;
}
.mr-2 {
  margin-right: 16px;
}
.mr-3 {
  margin-right: 24px;
}
.mr-4 {
  margin-right: 32px;
}
.mt-0 {
  margin-top: 0px;
}
.mt-1 {
  margin-top: 8px;
}
.mt-2 {
  margin-top: 16px;
}
.mt-3 {
  margin-top: 24px;
}
.mt-4 {
  margin-top: 32px;
}
.mb-0 {
  margin-bottom: 0px;
}
.mb-1 {
  margin-bottom: 8px;
}
.mb-2 {
  margin-bottom: 16px;
}
.mb-3 {
  margin-bottom: 24px;
}
.mb-4 {
  margin-bottom: 32px;
}
.p-0 {
  padding: 0;
}
.p-1 {
  padding: 8px;
}
.p-2 {
  padding: 16px;
}
.p-3 {
  padding: 24px;
}
.p-4 {
  padding: 32px;
}
.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.px-1 {
  padding-left: 8px;
  padding-right: 8px;
}
.px-2 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-3 {
  padding-left: 24px;
  padding-right: 24px;
}
.px-4 {
  padding-left: 32px;
  padding-right: 32px;
}
.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}
.py-1 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.py-2 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-3 {
  padding-top: 24px;
  padding-bottom: 24px;
}
.py-4 {
  padding-top: 32px;
  padding-bottom: 32px;
}
.pl-0 {
  padding-left: 0px;
}
.pl-1 {
  padding-left: 8px;
}
.pl-2 {
  padding-left: 16px;
}
.pl-3 {
  padding-left: 24px;
}
.pl-4 {
  padding-left: 32px;
}
.pr-0 {
  padding-right: 0px;
}
.pr-1 {
  padding-right: 8px;
}
.pr-2 {
  padding-right: 16px;
}
.pr-3 {
  padding-right: 24px;
}
.pr-4 {
  padding-right: 32px;
}
.pt-0 {
  padding-top: 0px;
}
.pt-1 {
  padding-top: 8px;
}
.pt-2 {
  padding-top: 16px;
}
.pt-3 {
  padding-top: 24px;
}
.pt-4 {
  padding-top: 32px;
}
.pb-0 {
  padding-bottom: 0px;
}
.pb-1 {
  padding-bottom: 8px;
}
.pb-2 {
  padding-bottom: 16px;
}
.pb-3 {
  padding-bottom: 24px;
}
.pb-4 {
  padding-bottom: 32px;
}
input {
  width: 100%;
  border: solid 1px #555;
  padding: 0px 8px;
  max-width: 100%;
  transition: all 0.3s;
  border-radius: 4px;
}
input:focus {
  font-size: 18px;
}
input:focus-within {
  outline: none;
}
.d-none {
  display: none;
}
.z0 {
  z-index: 0;
}
.z1 {
  z-index: 1;
}
.z2 {
  z-index: 2;
}
.z3 {
  z-index: 3;
}
.z4 {
  z-index: 4;
}
.z5 {
  z-index: 5;
}
.border {
  border: solid 1px #eeea;
}
button {
  outline: none;
  border: none;
  cursor: pointer;
  background: #ffc409;
  /* opacity: 0.85; */
  border-radius: 8px;
  /* min-height: 64px; */
  opacity: 1;
  font-size: 20px;
  transition: all 0.3s;
  padding: 8px 16px;
}
button:disabled {
  opacity: 0.75;
  pointer-events: none;
}
.borderBox {
  box-sizing: border-box;
}
.opacity0 {
  opacity: 0;
  transition: all 0.4s;
}
.opacity1 {
  opacity: 1;
  transition: all 0.4s;
}
.titleText {
  font-size: var(--headerFontSize);
}
.cancelButton {
  background: var(--cancelButton);
}
.failButton {
  background: var(--failColor);
}
.successButton {
  background: var(--successColor);
}
.confirmButton {
  background: var(--primaryColor);
}
.enter {
  bottom: -150%;
  transition: all 0.3s;
}
.enterDone {
  bottom: 50%;
  transition: all 0.3s;
}
.absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.fixCenter {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.clickElseWhere {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.fade {
  animation: fade-in 0.4s;
}
.selectedTab {
  background: #fff;
  opacity: 1;
  transition: all 0.3s;
  border-radius: 8px 8px 0 0;
  border: solid 1px #aaa;
  border-bottom: solid 1px #fff;
  font-size: 20px;
}
.unselectedTab {
  background: #ddd;
  opacity: 0.5;
  transition: all 0.3s;
  border-radius: 8px 8px 0 0;
  border-bottom: solid #aaa 1px;
  box-shadow: inset 0 0px 4px #fff;
}
.waiverText p,
.waiverText p strong {
  font-size: 10px;
  color: #333;
}

.partAgree {
  font-size: 20px;
  color: #222;
  margin-bottom: 16px;
  margin-top: 8px;
}
