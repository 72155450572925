.modal {
  position: absolute;
  bottom: -150%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #fff;
  padding: 16px 32px;
  box-shadow: 0 3px 8px #0004;
  border-radius: 8px;
  min-width: 310px;
  max-width: 99vw;
  /* width: max-content; */
  transition: all 0.3s;
  /* animation: modalSlide 0.6s forwards; */
}
.titleText p {
  color: #fff;
  font-size: 18px;
}
.waiverModal .titleText {
  /* height: 50%; */
  /* min-width: 100%; */
  max-height: 100%;
  max-width: 90%;
  overflow-y: auto;
}
.modalContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: modalSlide 0.6s forwards;
}
.sessionNumberModal {
  position: absolute;
  bottom: -150%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #fff;
  padding: 16px 24px;
  box-shadow: 0 3px 8px #0004;
  border-radius: 8px;
  min-width: 50vw;
  min-height: 10vh;
  max-height: 80vh;
  max-width: 100vw;
  width: 80vw;
  overflow-y: auto;
  box-sizing: border-box;
  /* width: max-content; */
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* animation: modalSlide 0.6s forwards; */
}
.waiverModal {
  position: absolute;
  bottom: -150%;
  left: 50%;
  transform: translate(-50%, 50%);
  background: #fff;
  padding: 16px 24px;
  box-shadow: 0 3px 8px #0004;
  border-radius: 8px;
  min-width: 50vw;
  min-height: 10vh;
  max-width: 100vw;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  box-sizing: border-box;
  /* width: max-content; */
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /* animation: modalSlide 0.6s forwards; */
}
@keyframes modalSlide {
  from {
    bottom: -150%;
  }
  to {
    bottom: 50%;
  }
}
.waiverContainer {
  text-align: left;
}
.waiverContainer strong {
  color: #222;
  font-weight: bolder;
}
